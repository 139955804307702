<template>
<div>
<div class="hidden md:block">
  <carousel :loop=true :autoplay-timeout=4000 :autoplay=true :per-page="1" :mouse-drag="true"
      paginationPosition="bottom-overlay" paginationColor="#A0AEC0" paginationActiveColor="#ffff" class="hero h-full w-full bg-yellow-400">
      <slide v-for="(slide, index) in jsonMisc.crouselImagesDesk" :key="index" class="hero h-full bg-orange-400">
        <img key="index" width="100%" :src="slide.banerUrl" :alt="`emmkay-baner-${index+1}`" style="background-size: cover" />
        <!-- <div class="text-white antialiased h-full w-full pl-8 pt-8 bg-blue-400" :style="`background-image: url('${slide.banerUrl}'); background-size: cover`" /> -->
      </slide>
    </carousel>
  </div>
<div class="block md:hidden">
    <carousel :loop=true :autoplay-timeout=4000 :autoplay=true :per-page="1" :mouse-drag="true"
      paginationPosition="bottom-overlay" paginationColor="#A0AEC0" paginationActiveColor="#ffff" class="hero h-full w-full bg-yellow-400">
      <slide v-for="(slide, index) in jsonMisc.crouselImagesMob" :key="index" class="hero h-full bg-orange-400">
        <img key="index" width="100%" :src="slide.banerUrl" :alt="`emmkay-baner-${index+1}`" style="background-size: cover" />
        <!-- <div class="text-white antialiased h-full w-full pl-8 pt-8 bg-blue-400" :style="`background-image: url('${slide.banerUrl}'); background-size: cover`" /> -->
      </slide>
    </carousel>
  </div>
  </div>
</template>

<script>
// import jsonData from "@/assets/json/data.json";

export default {
  data() {
    return {

    }
  },
  methods: {
    hello() {
      alert('Out of Scope');
    },
  },
  props: {
    jsonMisc: Object,
  },
};
</script>

<style>
.VueCarousel-pagination {
  width: 100%;
  text-align: left !important;
}
.VueCarousel-pagination--bottom-overlay {
  padding-top: 0 !important;
}
.VueCarousel-dot-container {
  display: flex !important;
  justify-content: center !important;
  margin-top: 1px !important;
}
.VueCarousel-slide {
  height: auto !important;
}
</style>
